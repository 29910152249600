import { mapActions } from 'vuex'
import { SnackbarStyle } from '@/constants/constants'
import i18n from '@/i18n'
import authApi from '@/api/auth.api'

const regExEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

export default {
  name: 'EmailResetPasswordComponent',
  data () {
    return {
      email: '',
      username: '',
      submitted: false,
      validForm: false,
      rules: {
        required: value => !!value || `${i18n.t('required')}`,
        email: value => regExEmail.test(value.toString()) || `${i18n.t('invalidEmail')}`
      }
    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    async submit () {
      this.submitted = true
      const config = {
        headers: {
          'accept-language': localStorage.getItem('locale')
        }
      }
      authApi.sendResetPasswordEmail(this.email, this.username, config).then(() => {
        this.showSnackbar({ visible: true, text: this.$t('resetPassword.successfulEmailPasswordReset'), timeout: 6000, style: SnackbarStyle.SUCCESS })
        setTimeout(() => {
          this.$router.push('/').catch(err => {
            console.log(err)
          })
        }, 6000)
      }).catch(() => {
        this.submitted = false
      })
    },
    prevent () {}
  }
}
